import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { dataTable } from "variables/general";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select2 from "react-select2-wrapper";
import { DateRangePicker } from "react-date-range";
import Collapse from "@material-ui/core/Collapse";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
var now = new Date();

const { SearchBar } = Search;
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});
function GudangLaporan() {
  const [RangeDate, setRangeDate] = React.useState(false);
  const [selectionRange, setSelectionRange] = React.useState([
    {
      startDate: new Date(now.getFullYear(), now.getMonth(), 1, 0),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      key: "selection",
    },
  ]);
  return (
    <>
      <SimpleHeader name="Laporan" parentName="Gudang" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Laporan Gudang</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <ToolkitProvider
                          data={dataTable}
                          keyField="name"
                          columns={[
                            {
                              dataField: "name",
                              text: "Id Barang",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Nama Barang",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Satuan",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Jumlah",
                              sort: true,
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <Row>
                                <Col md="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="LabaDiTahan"
                                    >
                                      Range Date
                                    </label>
                                    <Row>
                                      <Button
                                        className="col-md-12"
                                        color={
                                          RangeDate ? "secondary" : "primary"
                                        }
                                        type="button"
                                        onClick={(e) =>
                                          setRangeDate(!RangeDate)
                                        }
                                      >
                                        {RangeDate ? "Hidden" : "Show"}
                                      </Button>
                                    </Row>
                                    <Collapse
                                      // in={param.all == false && param.filter}
                                      in={RangeDate}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <div style={{ display: "inline-block" }}>
                                        <DateRangePicker
                                          // onChange={(item) =>
                                          //   setSelectionRange(
                                          //     [item.selection],
                                          //     setParam((prevState) => ({
                                          //       ...prevState,
                                          //       startdate: moment(
                                          //         item.selection.startDate
                                          //       ).format("YYYY-MM-DD"),
                                          //       enddate: moment(
                                          //         item.selection.endDate
                                          //       ).format("YYYY-MM-DD"),
                                          //     }))
                                          //   )
                                          // }
                                          showSelectionPreview={true}
                                          moveRangeOnFirstSelection={false}
                                          months={2}
                                          ranges={selectionRange}
                                          direction="horizontal"
                                        />
                                        ;
                                      </div>
                                    </Collapse>
                                  </FormGroup>
                                </Col>
                                <Col md="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="LabaDiTahan"
                                    >
                                      Aksi
                                    </label>
                                    <Button
                                      className="col-md-12"
                                      color="success"
                                      type="button"
                                    >
                                      Cetak
                                    </Button>
                                  </FormGroup>
                                </Col>{" "}
                              </Row>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                              >
                                <label className="mr-4">
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>

                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default GudangLaporan;
