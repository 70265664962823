import Ubahdataperusahaan from "views/pages/master/ubahdataperusahaan";
import Perkiraan from "views/pages/master/perkiraan";
import Jamaah from "views/pages/master/jamaah";
import Supplier from "views/pages/master/supplier";
import Pajak from "views/pages/master/pajak";
import KonfigurasiDefault from "views/pages/master/konfigurasidefault";
import Jurnal from "views/pages/laporan/jurnal";
import Neraca from "views/pages/laporan/neraca";
import SurplusDevisit from "views/pages/laporan/surplusdevisit";
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/examples/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";
import Devisi from "views/pages/master/devisi";
import Inventory from "views/pages/master/inventory";
import User from "views/pages/master/user";
import SaldoAwal from "views/pages/master/saldoawal";
import Pemesanan from "views/pages/kas-masuk/pemesanan";
import PenerimaanLangsung from "views/pages/kas-masuk/penerimaan-langsung";
import SetoranBendahara from "views/pages/kas-masuk/setoran-kas-masuk-ke-bendahara";
import BonSementara from "views/pages/kas-keluar/bon-sementara";
import RealisasiBS from "views/pages/kas-keluar/realisasi-bs";
import KasKeluarTanpaBs from "views/pages/kas-keluar/kas-keluar-tanpa-bs";
import JurnalEntry from "views/pages/akutansi/jurnal-entry";
import RealisasiPesanan from "views/pages/kas-masuk/realisasi-pesanan";
import PembukuanKasKeluar from "views/pages/kas-keluar/pembukuan-kas-keluar";
import MasterBarang from "views/pages/gudang/master-barang";
import BarangMasuk from "views/pages/gudang/barang-masuk";
import BarangKeluar from "views/pages/gudang/barang-keluar";
import GudangLaporan from "views/pages/gudang/laporan";
const routes = [
  {
    collapse: true,
    name: "Master",
    icon: "ni ni-shop text-primary",
    state: "mastersCollapse",
    views: [
      {
        path: "/ubah-data-perusahaan",
        name: "Ubah Data Perusahaan",
        miniName: "D",
        component: Ubahdataperusahaan,
        layout: "/admin",
      },
      {
        path: "/perkiraan",
        name: "perkiraan",
        miniName: "A",
        component: Perkiraan,
        layout: "/admin",
      },
      {
        path: "/jamaah",
        name: "Jama'ah",
        miniName: "A",
        component: Jamaah,
        layout: "/admin",
      },
      {
        path: "/supplier",
        name: "Supplier",
        miniName: "A",
        component: Supplier,
        layout: "/admin",
      },
      {
        path: "/pajak",
        name: "Pajak",
        miniName: "A",
        component: Pajak,
        layout: "/admin",
      },
      {
        path: "/konfigurasi-default",
        name: "Konfigurasi Default",
        miniName: "A",
        component: KonfigurasiDefault,
        layout: "/admin",
      },
      {
        path: "/devisi",
        name: "Devisi",
        miniName: "A",
        component: Devisi,
        layout: "/admin",
      },
      {
        path: "/inventory",
        name: "Inventory",
        miniName: "A",
        component: Inventory,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "User",
        miniName: "A",
        component: User,
        layout: "/admin",
      },
      {
        path: "/saldo-awal",
        name: "Saldo Awal",
        miniName: "A",
        component: SaldoAwal,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Kas Masuk",
    icon: "ni ni-money-coins text-primary",
    state: "KasMasukCollapse",
    views: [
      {
        path: "/pemesanan",
        name: "Pemesanan",
        miniName: "P",
        component: Pemesanan,
        layout: "/admin",
      },
      {
        path: "/realisasi-pesanan",
        name: "Realisasi Pesanan",
        miniName: "P",
        component: RealisasiPesanan,
        layout: "/admin",
      },
      {
        path: "/penerimaan-langsung",
        name: "Penerimaan Langsung",
        miniName: "P",
        component: PenerimaanLangsung,
        layout: "/admin",
      },
      {
        path: "/setoran-kas-masuk-ke-bendahara",
        name: "Setoran KM Ke Bendahara",
        miniName: "P",
        component: SetoranBendahara,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Kas Keluar",
    icon: "ni ni-archive-2 text-primary",
    state: "KasKeluarCollapse",
    views: [
      {
        path: "/bon-sementara",
        name: "Bon Sementara",
        miniName: "P",
        component: BonSementara,
        layout: "/admin",
      },
      {
        path: "/realisasi-bs",
        name: "Realisasi BS",
        miniName: "P",
        component: RealisasiBS,
        layout: "/admin",
      },
      {
        path: "/kas-keluar-tanpa-bs",
        name: "Kas Keluar Tanpa BS",
        miniName: "P",
        component: KasKeluarTanpaBs,
        layout: "/admin",
      },
      {
        path: "/pembukuan-kas-keluar",
        name: "Pembukuan Kas Keluar",
        miniName: "P",
        component: PembukuanKasKeluar,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Akutansi",
    icon: "ni ni-collection text-primary",
    state: "AkutansiCollapse",
    views: [
      {
        path: "/jurnal-entry",
        name: "Jurnal Entry",
        miniName: "P",
        component: JurnalEntry,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Gudang",
    icon: "ni ni-app text-primary",
    state: "GudangCollapse",
    views: [
      {
        path: "/Master-Barang",
        name: "Master Barang",
        miniName: "P",
        component: MasterBarang,
        layout: "/admin",
      },
      {
        path: "/barang-masuk",
        name: "Barang Masuk",
        miniName: "P",
        component: BarangMasuk,
        layout: "/admin",
      },
      {
        path: "/barang-keluar",
        name: "Barang Keluar",
        miniName: "P",
        component: BarangKeluar,
        layout: "/admin",
      },
      {
        path: "/laporan",
        name: "Laporan",
        miniName: "P",
        component: GudangLaporan,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Laporan",
    icon: "ni ni-book-bookmark text-primary",
    state: "LaporanCollapse",
    views: [
      {
        path: "/lp-jurnal",
        name: "Jurnal",
        miniName: "P",
        component: Jurnal,
        layout: "/admin",
      },
      {
        path: "/neraca",
        name: "Neraca",
        miniName: "P",
        component: Neraca,
        layout: "/admin",
      },
      {
        path: "/surplus-defisit",
        name: "Surplus Defisit",
        miniName: "P",
        component: SurplusDevisit,
        layout: "/admin",
      },
    ],
  },
  // sini
  // {
  //   collapse: true,
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   views: [
  //     {
  //       path: "/dashboard",
  //       name: "Dashboard",
  //       miniName: "D",
  //       component: Dashboard,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/alternative-dashboard",
  //       name: "Alternative",
  //       miniName: "A",
  //       component: Alternative,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Examples",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "examplesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       miniName: "P",
  //       component: Pricing,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       miniName: "L",
  //       component: Login,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       miniName: "R",
  //       component: Register,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/lock",
  //       name: "Lock",
  //       miniName: "L",
  //       component: Lock,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       miniName: "T",
  //       component: Timeline,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       miniName: "P",
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       miniName: "RS",
  //       component: RTLSupport,
  //       layout: "/rtl",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       miniName: "B",
  //       component: Buttons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       miniName: "C",
  //       component: Cards,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid",
  //       name: "Grid",
  //       miniName: "G",
  //       component: Grid,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       miniName: "N",
  //       component: Notifications,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       miniName: "I",
  //       component: Icons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       miniName: "T",
  //       component: Typography,
  //       layout: "/admin",
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       miniName: "M",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/elements",
  //       name: "Elements",
  //       miniName: "E",
  //       component: Elements,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/components",
  //       name: "Components",
  //       miniName: "C",
  //       component: Components,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation",
  //       name: "Validation",
  //       miniName: "V",
  //       component: Validation,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/tables",
  //       name: "Tables",
  //       miniName: "T",
  //       component: Tables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sortable",
  //       name: "Sortable",
  //       miniName: "S",
  //       component: Sortable,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-bs-table",
  //       name: "React BS Tables",
  //       miniName: "RBT",
  //       component: ReactBSTables,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/admin",
  // },
];
export default routes;
