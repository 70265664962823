import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

function Ubahdataperusahaan() {
  const [yourName, setyourName] = React.useState(false);
  const [yourAddress, setyourAddress] = React.useState(false);
  return (
    <>
      <SimpleHeader name="Data Perusaan" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Data Perusahaan</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: yourName,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Nama Perusahaan"
                              type="text"
                              onFocus={(e) => setyourName(true)}
                              onBlur={(e) => setyourName(false)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: yourName,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-address-card" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Alamat Perusahaan"
                              type="text"
                              onFocus={(e) => setyourAddress(true)}
                              onBlur={(e) => setyourAddress(false)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className=" col-auto">
                        <Button size="md" color="primary" data-dz-remove>
                          <i className="fas fa-plus" /> Ubah Data Perusahaan
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Ubahdataperusahaan;
