import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

function KonfigurasiDefault() {
  const [yourName, setyourName] = React.useState(false);
  const [yourAddress, setyourAddress] = React.useState(false);
  return (
    <>
      <SimpleHeader name="Data Perusaan" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Data Konfigurasi Default</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Laba Ditahan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Laba Ditahan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PembelianTunai"
                          >
                            Pembelian Tunai
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Hutang Pembalian
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PenjualanTunai"
                          >
                            Penjualan Tunai
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PiutangPenjualan"
                          >
                            Piutang Penjualan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Biaya HPP Item
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PendapatanPenjualan"
                          >
                            Pendapan Penjualan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Biaya HPP Item
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Pendapatan Penjualan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PersendianItem"
                          >
                            Persediaan Item
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PajakPembelian"
                          >
                            Pajak Pembelian
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Pajak Penjualan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Beban Pajak
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="LabaDiTahan"
                          >
                            Pembatalan Penjualan
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="PembatalanPembelian"
                          >
                            Pembatalan Pembelian
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="1"
                            options={{
                              placeholder: "Select",
                            }}
                            data={[
                              { id: "1", text: "Dummy" },
                              { id: "2", text: "Badges" },
                              { id: "3", text: "Buttons" },
                              { id: "4", text: "Cards" },
                              { id: "5", text: "Forms" },
                              { id: "6", text: "Modals" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className=" col-auto">
                        <Button size="md" color="primary" data-dz-remove>
                          <i className="fas fa-plus" /> Ubah Konfigurasi Default
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default KonfigurasiDefault;
