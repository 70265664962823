import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Card,
  CardHeader,
  Button,
  Col,
  CardBody,
  Form,
  Container,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Search } from "react-bootstrap-table2-toolkit";
import { multi } from "variables/general";
import {
  useTable,
  useExpanded,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

function Table({ columns: userColumns, data, globalFilter }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        globalFilter,
        autoResetGlobalFilter: true,
      },
    },
    useGlobalFilter,
    useExpanded // Use the useExpanded plugin hook
  );
  React.useEffect(() => {
    if (globalFilter !== undefined) {
      console.log(globalFilter);
      setGlobalFilter(globalFilter);
    }
  }, [globalFilter, setGlobalFilter]);

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="list">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function Perkiraan() {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const onChange = (e) => setGlobalFilter(e.target.value);
  const [selected, setSelected] = React.useState({});
  const [selectAll, setSelectAll] = React.useState(0);
  const toggleRow = (id) => {
    const newSelected = { ...selected };
    newSelected[id] = !selected[id];

    setSelected(newSelected);
    setSelectAll(2);
  };

  const toggleSelectAll = () => {
    let newSelected = {};

    if (selectAll === 0) {
      multi.forEach((x) => {
        newSelected[x.firstName] = true;
      });
    }

    setSelected(newSelected);
    setSelectAll(selectAll === 0 ? 1 : 0);
  };
  const columns = React.useMemo(
    () => [
      {
        id: "checkbox",
        width: 20,
        accessor: "",
        Cell: ({ row }) => (
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="table-check-all"
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="table-check-all" />
          </div>
        ),
        Header: (x) => {
          return (
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                id="table-check-all"
                type="checkbox"
              />
              <label
                className="custom-control-label"
                htmlFor="table-check-all"
              />
            </div>
          );
        },
      },
      {
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <Button
                className="btn-warning btn-icon btn-sm"
                color="warning"
                type="button"
              >
                <i className="fas fa-minus" />
              </Button>
            ) : (
              <Button
                className="btn-slack btn-icon btn-sm"
                color="default"
                type="button"
              >
                <i className="fas fa-plus" />
              </Button>
            )}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <Button
                  className="btn-warning btn-icon btn-sm"
                  color="warning"
                  type="button"
                >
                  <i className="fas fa-minus" />
                </Button>
              ) : (
                <Button
                  className="btn-slack btn-icon btn-sm"
                  color="default"
                  type="button"
                >
                  <i className="fas fa-plus" />
                </Button>
              )}
            </span>
          ) : null,
      },
      {
        Header: "Name",
        accessor: "firstName",
      },
      {
        Header: "Nama Akun",
        accessor: "lastName",
      },
    ],
    []
  );

  return (
    <>
      <SimpleHeader name="Perkiraan" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Data Perkiraan</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <div className="py-4 table-responsive">
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1"
                          >
                            <label className="mr-4">
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                value={globalFilter || ""}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            </label>
                            <Button
                              className="btn-slack btn-icon btn-sm"
                              color="default"
                              type="button"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text">Tambah</span>
                            </Button>
                            <Button
                              className="btn btn-warning btn-sm"
                              type="button"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-pencil-alt" />
                              </span>
                              <span className="btn-inner--text">Edit</span>
                            </Button>
                            <Button
                              className="btn-google-plus btn-icon btn btn-google btn-sm"
                              color="default"
                              type="button"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-minus" />
                              </span>
                              <span className="btn-inner--text">Hapus</span>
                            </Button>
                          </div>
                          <Table
                            columns={columns}
                            data={multi}
                            globalFilter={globalFilter}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Perkiraan;
