import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { dataTable } from "variables/general";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

function Pajak() {
  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    hideSelectAll: true,
    selectColumnStyle: { width: 40 },
    // selected: selectedOrderIndex,
    onSelect: (row, isSelect, rowIndex, e) => {
      // if (isSelect) {
      //   if (row.redeemstatus != "pending") {
      //     return false;
      //   } else {
      //     let exsist = false;
      //     currentSelectedOrder.forEach((elm, i) => {
      //       if (elm == row.id) {
      //         exsist = true;
      //       }
      //     });
      //     if (!exsist) {
      //       // console.log(row);
      //       let current = _.cloneDeep(currentSelectedOrder);
      //       current.push(row);
      //       setCurrentSelectedOrder(current);
      //       let temp = selectedOrderIndex;
      //       temp.push(row.id);
      //       setSelectedOrderIndex(temp);
      //       toast.dismiss();
      //       toast.success("Order List ditambahkan!", {
      //         containerId: "B",
      //         transition: Zoom,
      //       });
      //     }
      //   }
      // } else {
      //   let removedIndex = 0;
      //   currentSelectedOrder.map((orderData, index) => {
      //     if (orderData.id == row.id) {
      //       removedIndex = index;
      //     }
      //   });
      //   let current = _.cloneDeep(currentSelectedOrder);
      //   current.splice(removedIndex, 1);
      //   setCurrentSelectedOrder(current);
      //   let temp = selectedOrderIndex;
      //   temp.splice(removedIndex, 1);
      //   setSelectedOrderIndex(temp);
      // }
    },
  };
  return (
    <>
      <SimpleHeader name="Pajak" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Data Pajak</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <ToolkitProvider
                          data={dataTable}
                          keyField="name"
                          columns={[
                            {
                              dataField: "name",
                              text: "Kode",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Keterangan",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Besar Pajak",
                              sort: true,
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                              >
                                <label className="mr-4">
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                                <Button
                                  className="btn-slack btn-icon btn-sm"
                                  color="default"
                                  type="button"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Tambah
                                  </span>
                                </Button>
                                <Button
                                  className="btn btn-warning btn-sm"
                                  type="button"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-pencil-alt" />
                                  </span>
                                  <span className="btn-inner--text">Edit</span>
                                </Button>
                                <Button
                                  className="btn-google-plus btn-icon btn btn-google btn-sm"
                                  color="default"
                                  type="button"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-minus" />
                                  </span>
                                  <span className="btn-inner--text">Hapus</span>
                                </Button>
                              </div>
                              <BootstrapTable
                                {...props.baseProps}
                                selectRow={selectRow}
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Pajak;
