import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { dataTable } from "variables/general";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select2 from "react-select2-wrapper";
import { DateRangePicker } from "react-date-range";
import Collapse from "@material-ui/core/Collapse";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
var now = new Date();

const { SearchBar } = Search;
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});
function Neraca() {
  return (
    <>
      <SimpleHeader name="Neraca" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Laporan Neraca</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <ToolkitProvider
                          data={dataTable}
                          keyField="name"
                          columns={[
                            {
                              dataField: "name",
                              text: "Perkiraan",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Debet",
                              sort: true,
                            },
                            {
                              dataField: "position",
                              text: "Kredit",
                              sort: true,
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <div className="py-4 table-responsive">
                                <Row>
                                  <Col md="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="LabaDiTahan"
                                      >
                                        Filter Tahun
                                      </label>
                                      <Select2
                                        className="form-control"
                                        defaultValue="1"
                                        options={{
                                          placeholder: "Select",
                                        }}
                                        data={[
                                          { id: "1", text: "2022" },
                                          { id: "2", text: "Badges" },
                                          { id: "3", text: "Buttons" },
                                          { id: "4", text: "Cards" },
                                          { id: "5", text: "Forms" },
                                          { id: "6", text: "Modals" },
                                        ]}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="LabaDiTahan"
                                      >
                                        Filter Bulan
                                      </label>
                                      <Select2
                                        className="form-control"
                                        defaultValue="1"
                                        options={{
                                          placeholder: "Select",
                                        }}
                                        data={[
                                          { id: "1", text: "January" },
                                          { id: "2", text: "Badges" },
                                          { id: "3", text: "Buttons" },
                                          { id: "4", text: "Cards" },
                                          { id: "5", text: "Forms" },
                                          { id: "6", text: "Modals" },
                                        ]}
                                      />
                                    </FormGroup>
                                  </Col>{" "}
                                  <Col md="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="LabaDiTahan"
                                      >
                                        Aksi
                                      </label>
                                      <Button
                                        className="col-md-12"
                                        color="success"
                                        type="button"
                                      >
                                        Cetak
                                      </Button>
                                    </FormGroup>
                                  </Col>{" "}
                                </Row>
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Neraca;
